import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const SearchByProject = () => {
  const [year, setYear] = useState('2024');
  const [projectId, setProjectId] = useState('');
  const [graphData, setGraphData] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_LOCALHOST_API_LINK}/get_rejected_sqm_by_project`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          project_id: projectId,
          year: year,
        }),
      });

      const data = await response.json();

      if (data) {
        // Map response data to format expected by BarChart
        const formattedData = data.map(entry => ({
          Department: entry.DepartmentName,
          processed: parseFloat(entry.processedsqm),
          totalRejected: [
            parseFloat(entry.TotalSQMRejectedByCutting),
            parseFloat(entry.TotalSQMRejectedByEdging),
            parseFloat(entry.TotalSQMRejectedByFabrication),
            parseFloat(entry.TotalSQMRejectedByIG),
            parseFloat(entry.TotalSQMRejectedByLamination),
            parseFloat(entry.TotalSQMRejectedByToughening),
          ].reduce((acc, val) => acc + val, 0),
          rejectionDetails: {
            Cutting: parseFloat(entry.TotalSQMRejectedByCutting) || 0,
            Edging: parseFloat(entry.TotalSQMRejectedByEdging) || 0,
            Fabrication: parseFloat(entry.TotalSQMRejectedByFabrication) || 0,
            IG: parseFloat(entry.TotalSQMRejectedByIG) || 0,
            Lamination: parseFloat(entry.TotalSQMRejectedByLamination) || 0,
            Toughening: parseFloat(entry.TotalSQMRejectedByToughening) || 0,
          }
        }));
        setGraphData(formattedData);
      } else {
        alert('No data found for the selected year and Project ID');
        setGraphData(null);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      alert('Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    fetchData();
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const department = label;
      const totalProcessed = payload.find(p => p.dataKey === 'processed')?.value;
      const rejectionDetails = payload.find(p => p.dataKey === 'totalRejected')?.payload.rejectionDetails;

      return (
        <div className="custom-tooltip" style={{
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          padding: '10px',
          border: '1px solid #ccc',
          color: '#fff',
          borderRadius: '5px',
        }}>
          <p className="label">{`Department: ${department}`}</p>
          <p style={{ color: '#00E396' }}>
            {`Total SQM Processed: ${totalProcessed}`}
          </p>
          <p style={{ color: 'red' }}>
            {`Total SQM Rejected: ${rejectionDetails.Cutting + rejectionDetails.Edging + rejectionDetails.Fabrication + rejectionDetails.IG + rejectionDetails.Lamination + rejectionDetails.Toughening}`}
          </p>
          
        </div>
      );
    }
    return null;
  };

  return (
    <div style={{ padding: '20px' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
        <label htmlFor="yearSelect" style={{ marginRight: '15px', fontSize: '16px' }}>
          Year
          <select
            id="yearSelect"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            style={{
              padding: '10px',
              fontSize: '16px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              marginLeft: '10px',
              outline: 'none',
            }}
          >
            <option value="2020">2020</option>
            <option value="2021">2021</option>
            <option value="2022">2022</option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
          </select>
        </label>
        <label htmlFor="projectIdInput" style={{ marginRight: '15px', fontSize: '16px' }}>
          Project ID
          <input
            id="projectIdInput"
            type="text"
            value={projectId}
            onChange={(e) => setProjectId(e.target.value)}
            style={{
              padding: '10px',
              fontSize: '16px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              marginLeft: '10px',
              outline: 'none',
            }}
          />
        </label>
        <button
          style={{
            padding: '10px 18px',
            fontSize: '16px',
            borderRadius: '5px',
            background: '#7A03CA',
            color: '#fff',
            border: 'none',
            cursor: 'pointer',
          }}
          onClick={handleSearch}
          disabled={loading}
        >
          {loading ? 'Loading...' : 'Search'}
        </button>
      </div>
      {graphData && (
        <div style={{ height: '400px', marginTop: '20px' }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={graphData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="Department" label={{ value: 'Departments', position: 'insideBottomRight', offset: 0 }} />
              <YAxis label={{ value: 'SQM Processed', angle: -90, position: 'insideLeft' }} />
              <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip />} />
              <Legend />
              <Bar dataKey="processed" stackId="a" fill="#00E396" name="Total SQM Processed" barSize={20} isAnimationActive={false} />
              <Bar dataKey="totalRejected" stackId="a" fill="#FF4560" name="Total SQM Rejected" barSize={20} isAnimationActive={false} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};

export default SearchByProject;
