
import React, { useEffect, useState, useRef, act } from "react";
import { Tabs, Tab, Paper } from "@mui/material";
import { Col, Row, Button, Card, CardBody, CardTitle, Table } from "reactstrap";
import {
  AiOutlineFilePdf,
  AiOutlineFileText,
  AiOutlineFileJpg,
} from "react-icons/ai";
import { FaFileCsv, FaFileCode, FaFile, FaTrashAlt } from "react-icons/fa";
import { Modal, ModalBody } from "reactstrap";

import { TG1 } from "../apps/chat/TG1.tsx";
import { color } from "d3";
import { useNavigate } from "react-router-dom";
import {  } from "@mui/material";
import { Pagination , PaginationItem } from "@mui/material";

function TrainingGround() {
  const [tabValue, setTabValue] = useState(0);
  const [files, setFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const navigate = useNavigate(); 

  useEffect(() => {
    getFiles();
  }, []);

  const getFiles = async () => {
  
    try {
  
      const response = await fetch(
        `${process.env.REACT_APP_LOCALHOST_API_LINK}/fileMetaData`,
        {
          // headers: {
            // Authorization: `Bearer ${token}`,
          // },
        }
      );
  
      if (!sessionStorage.getItem("msal.account.keys")) {
        console.log("Unauthorized access. Redirecting to login page...");
        navigate('/login'); 
        return;
      }
  
      const data = await response.json();
      console.log(data, "Data");
  
      const formattedFiles = data.MetaData.map((file) => ({
        name: file.fileName,
        format: file.fileName.split(".").pop(),
        link: file.fileUrl,
        nameSpaceName: file.nameSpaceName,
      }));
  
      setFiles(formattedFiles);
    } catch (error) {
      console.error("Error fetching file metadata:", error);
    }
  };

  const handleFileSelection = (file, isChecked) => {
    if (isChecked) {
      setSelectedFiles([...selectedFiles, file]);
    } else {
      setSelectedFiles(
        selectedFiles.filter((selectedFile) => selectedFile !== file)
      );
    }
  };

  const handleSelectAllChange = (isChecked) => {
    setSelectAllChecked(isChecked);
    if (isChecked) {
      setSelectedFiles([...files]);
    } else {
      setSelectedFiles([]);
    }
  };

  const handleDeleteClick = () => {
    console.log("Delete clicked for:", selectedFiles);
    // Perform delete operation here
    setSelectedFiles([]);
    setSelectAllChecked(false);
  };

  const handleTrainDataClick = () => {
    console.log("Train Data clicked for:", selectedFiles);
    // Perform train data operation here
    setSelectedFiles([]);
    setSelectAllChecked(false);
  };

  return (
    <Row style={{ marginTop: "20px" }}>
      <Col md={12}>
        <Paper
          style={{
            backgroundColor: "#2F2F57",
            color: "white",
            padding: "20px",
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Train Datasets" style={{ color: "white" }} />
            <Tab label="Dataset Modelling" style={{ color: "white" }} />
          </Tabs>
          <div style={{ marginTop: "20px" }}>
            {tabValue === 0 && (
              <TrainDatasetsTab
                files={files}
                selectedFiles={selectedFiles}
                handleFileSelection={handleFileSelection}
                getFiles={getFiles}
                selectAllChecked={selectAllChecked}
                handleSelectAllChange={handleSelectAllChange}
                handleDeleteClick={handleDeleteClick}
                handleTrainDataClick={handleTrainDataClick}
              />
            )}
            {tabValue === 1 && <DatasetModelTab />}
          </div>
        </Paper>
      </Col>
    </Row>
  );
}

const TrainDatasetsTab = ({
  files,
  selectedFiles,
  handleFileSelection,
  getFiles,
  selectAllChecked,
  handleSelectAllChange,
  handleDeleteClick,
  handleTrainDataClick,
}) => {
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const [selectedFile, setSelectedFile] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const navigate = useNavigate(); 

  const openModal = (file) => {
    setSelectedFile(file);
    setIsOpen(true);
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    const formData = new FormData();
    formData.append("file", selectedFile);
  
    console.log(formData, "formData");  
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error('Access token not found in localStorage');
      }
  
      const response = await fetch(
        `${process.env.REACT_APP_LOCALHOST_API_LINK}/upload-file`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (!sessionStorage.getItem("msal.account.keys")) {
        console.log("Unauthorized access. Redirecting to login page...");
        navigate('/login'); 
        return;
      }
  
      const data = await response.json();
      if (data.fileData) {
        setUploadError(null);
        getFiles();
        setLoading(false);
      } else {
        setLoading(false);
        setTimeout(() => {
          setUploadError(data.detail);
        }, 5000);
        console.log(data.detail, "User Uploaded Info");
      }
    } catch (error) {
      console.error(error);
      setUploadError("An error occurred during upload.");
    }
  };
  return (
    <Row>
      <Col md={12}>
        <Row>
          <Col className="text-end">
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <Button
              onClick={handleButtonClick}
              style={{
                backgroundColor: "#7A00CC",
                color: "white",
                fontSize: "16px",
                padding: "8px",
                borderRadius: "10px",
                marginRight: "10px",
              }}
            >
              Add Document
            </Button>
          </Col>
        </Row>
        {loading && (
          <p style={{ display: "flex", justifyContent: "center" }}>
            Uploading File...
          </p>
        )}
        {uploadError && <p style={{ color: "red" }}>{uploadError}</p>}

        {!loading && (
          <Row
            style={{
              marginTop: "5px",
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
              height: "487px", 
              overflow: "hidden",
            }}
          >
            <div
              style={{
                overflowY: "auto",
                paddingRight: "15px",
                maxHeight: "100%",
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                scrollbarWidth: "none",
                "-ms-overflow-style": "none",
                "&::-webkit-scrollbar": {
                  width: "0",
                },
              }}
            >
              {files.map((file, index) => (
                <Col
                  key={index}
                  className="m-2"
                  md={3}
                  lg={3}
                  xxl={3}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "10px",
                  }}
                >
                  <FileItem
                    name={file.name}
                    isSelected={selectedFiles.includes(file)}
                    format={file.format}
                    handleFileSelection={handleFileSelection}
                    file={file}
                    openModal={openModal}
                    nameSpaceName={file.nameSpaceName}
                  />
                </Col>
              ))}
            </div>
          </Row>
        )}
        {!loading && (
          <Row style={{ padding: "10px" }}>
            <Col
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                checked={selectAllChecked}
                onChange={(e) => handleSelectAllChange(e.target.checked)}
                style={{ marginRight: "5px", cursor: "pointer" }}
              />
              Select All
              <Button
                onClick={handleDeleteClick}
                style={{
                  backgroundColor: "#7A00CC",
                  color: "white",
                  fontSize: "16px",
                  padding: "8px",
                  borderRadius: "10px",
                  marginRight: "10px",
                  marginLeft: "10px  ",
                }}
              >
                Delete
              </Button>
              <Button
                onClick={handleTrainDataClick}
                style={{
                  backgroundColor: "#7A00CC",
                  color: "white",
                  fontSize: "16px",
                  padding: "8px",
                  borderRadius: "10px",
                  marginRight: "10px",
                  marginLeft: "10px  ",
                }}
              >
                Train Data
              </Button>
            </Col>
          </Row>
        )}
      </Col>
      <Modal
        isOpen={isOpen}
        toggle={toggleModal}
        size="xl"
        style={{ borderRadius: "30px" }}
      >
        <ModalBody style={{ backgroundColor: "#2F2F57" }}>
          <TG1 nameSpaceName={selectedFile?.nameSpaceName} />
        </ModalBody>
      </Modal>
    </Row>
  );
};
const DatasetModelTab = () => {
  const [rejectionPredictions, setRejectionPredictions] = useState([]);
  const [machineBreakdownPredictions, setMachineBreakdownPredictions] = useState([]);
  const [workOrderPredictions, setWorkOrderPredictions] = useState([]);
  const [activePredictionType, setActivePredictionType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [showPredictionMessage, setShowPredictionMessage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const navigate = useNavigate();

  useEffect(() => {
    fetchTableData();
  }, []);

  const fetchRejectionPredictions = async () => {
    setLoading(true);
    setError(null);
    setSuccessMessage(null);
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${process.env.REACT_APP_LOCALHOST_API_LINK}/rejectionPrediction`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!sessionStorage.getItem("msal.account.keys")) {
        navigate("/login");
      } else {
        const data = await response.json();
        if (response.ok) {
          setSuccessMessage(data.message);
          setRejectionPredictions(data.all_predictions || []);
        } else {
          setError("Failed to fetch predictions.");
        }
      }
    } catch (error) {
      setError("An error occurred while fetching predictions.");
      console.error(error);
    }
    setLoading(false);
  };

  const fetchMachineBreakdownPredictions = async () => {
    setLoading(true);
    setError(null);
    setSuccessMessage(null);
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_LOCALHOST_API_LINK}/MachineDowntimePrediction`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (!sessionStorage.getItem("msal.account.keys")) {
        navigate("/login");
      } else if (response.ok) {
        setSuccessMessage(data.message);
        setMachineBreakdownPredictions(data.all_predictions || []);
      } else {
        setError("Failed to fetch predictions.");
      }
    } catch (error) {
      setError("An error occurred while fetching predictions.");
      console.error(error);
    }
    setLoading(false);
  };

  const fetchWorkOrderPredictions = async () => {
    setLoading(true);
    setError(null);
    setSuccessMessage(null);
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error('Access token not found in localStorage');
      }
      const response = await fetch(
        `${process.env.REACT_APP_LOCALHOST_API_LINK}/workorderPrediction`, 
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!sessionStorage.getItem("msal.account.keys")) {
        console.log("Unauthorized access. Redirecting to login page...");
        navigate('/login'); 
        return;
      }

      const data = await response.json();
      if (response.ok) {
        setSuccessMessage(data.message);
        setWorkOrderPredictions(data.all_predictions || []);
      } else {
        setError("Failed to fetch predictions.");
      }
    } catch (error) {
      setError("An error occurred while fetching predictions.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTableData = async () => {
    setLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_LOCALHOST_API_LINK}/getHistory`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            page: 4,
            page_size: 10,
          }),
        }
      );
      const data = await response.json();
      if (!sessionStorage.getItem("msal.account.keys")) {
        navigate("/login");
      } else if (response.ok) {
        // Sort data in descending order by ID
        const sortedData = (data.all_predictions || []).sort((a, b) => b.ID - a.ID);
        setTableData(sortedData);
      } else {
        setError("Failed to fetch table data.");
      }
    } catch (error) {
      setError("An error occurred while fetching table data.");
      console.error(error);
    }
    setLoading(false);
  };

  const handlePredictionTypeChange = async (type) => {
    setActivePredictionType(type);
    setShowPredictionMessage(true);
    if (type === "Rejection") {
      await fetchRejectionPredictions();
    } else if (type === "MachineBreakdown") {
      await fetchMachineBreakdownPredictions();
    } else if (type === "WorkOrder") { 
      await fetchWorkOrderPredictions();
    }
    setShowPredictionMessage(false);
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(tableData.length / itemsPerPage);

  return (
    <Row style={{ padding: "10px", display: "flex", justifyContent: "center" }}>
      <h2 style={{ color: "white", textAlign: "center" }}>Machine learning model execution</h2>
      <Button
        style={{
          backgroundColor: "#7A00CC",
          color: "white",
          fontSize: "16px",
          padding: "8px",
          borderRadius: "10px",
          marginRight: "10px",
          marginBottom: "10px",
          width: "200px",
        }}
        onClick={() => handlePredictionTypeChange("Rejection")}
      >
        Predict Rejections
      </Button>
      <Button
        style={{
          backgroundColor: "#7A00CC",
          color: "white",
          fontSize: "16px",
          padding: "8px",
          borderRadius: "10px",
          marginRight: "10px",
          marginBottom: "10px",
          width: "200px",
        }}
        onClick={() => handlePredictionTypeChange("MachineBreakdown")}
      >
        Predict Machine Breakdown
      </Button>
      <Button
        style={{
          backgroundColor: "#7A00CC",
          color: "white",
          fontSize: "16px",
          padding: "8px",
          borderRadius: "10px",
          marginRight: "10px",
          marginBottom: "10px",
          width: "200px",
        }}
        onClick={() => handlePredictionTypeChange("WorkOrder")} 
      >
        Predict Work Order Count
      </Button>

      {error && <p style={{ color: "red" }}>{error}</p>}
      {showPredictionMessage && <p style={{ color: "white" }}>QUEUED...</p>}
      {currentItems.length > 0 && (
        <Col md={12}>
          <Table striped>
            <thead>
              <tr>
                <th style={{ fontWeight: "bold" }}>ID</th>
                <th style={{ fontWeight: "bold" }}>TYPE</th>
                <th style={{ fontWeight: "bold" }}>PREDICTION TIMESTAMP</th>
                <th style={{ fontWeight: "bold" }}>COMPLETION TIMESTAMP</th>
                <th style={{ fontWeight: "bold" }}>STATUS</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item) => (
                <tr key={item.ID}>
                  <td>{item.ID}</td>
                  <td>{item.TYPE}</td>
                  <td>{item.PREDICTION_TS}</td>
                  <td>{item.COMPLETION_TS ? item.COMPLETION_TS : "N/A"}</td>
                  <td>{item.STATUS}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={(event, value) => setCurrentPage(value)}
              color="primary"
              variant="outlined"
              size="large"
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
              boundaryCount={2}
              renderItem={(item) => (
                <PaginationItem
                  {...item}
                  style={{ color: "white", borderColor: "#7A00CC", backgroundColor: item.selected ? "#7A00CC" : "transparent" }}
                />
              )}
            />
          </div>
        </Col>
      )}
    </Row>
  );
};






const FileItem = ({
  name,
  format,
  isSelected,
  handleFileSelection,
  file,
  openModal,
  setOpenModal,
  nameSpaceName,
}) => {
  const getFileIcon = (format) => {
    switch (format.toUpperCase()) {
      case "PDF":
        return <AiOutlineFilePdf size={56} />;
      case "TEXT":
        return <AiOutlineFileText size={56} />;
      case "CSV":
        return <FaFileCsv size={56} />;
      case "JPG":
      case "JPEG":
      case "PNG":
      case "WEBP":
      case "AVIF":
        return <AiOutlineFileJpg size={56} />;
      case "JSON":
        return <FaFileCode size={56} />;
      default:
        return <FaFile size={56} />;
    }
  };

  const getCardStyle = (format) => {
    switch (format.toUpperCase()) {
      default:
        return { height: "150px", width: "150px" };
    }
  };

  const handleDeleteClick = () => {
    console.log("Delete clicked for:", name, format);
  };

  const handleCardClick = () => {
    console.log("Card clicked:", name, format, nameSpaceName);
    openModal(file);
  };
  return (
    <Card style={getCardStyle(format)}>
      <CardBody
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          border: "2px solid #565080",
          borderRadius: "10px",
          position: "relative",
        }}
        onClick={handleCardClick}
      >
        <input
          type="checkbox"
          style={{
            position: "absolute",
            right: "125px",
            bottom: "10px",
            cursor: "pointer",
            color: "#992bfe",
          }}
          checked={isSelected}
          onChange={() => handleFileSelection(file, !isSelected)}
        />
        {getFileIcon(format)}
        <CardTitle tag="h5" style={{ marginTop: "10px" }}>
          <p style={{ marginTop: "10px", fontSize: "12px" }}>{name}</p>
        </CardTitle>
        <FaTrashAlt
          size={20}
          style={{
            position: "absolute",
            bottom: "-10px",
            left: "45%",
            cursor: "pointer",
            color: "#992bfe",
          }}
          onClick={handleDeleteClick}
        />
      </CardBody>
    </Card>
  );
};

export default TrainingGround;
