import React from "react";
import Chart from "react-apexcharts";

const AdditionalBarChart = ({ selectedMonth, additionalData, colorScheme, handleBackClick }) => {
  const series = Object.keys(additionalData).map((reason) => ({
    name: reason,
    data: [additionalData[reason]],
    color: colorScheme[reason] || "#777"  // Use a default color if the reason is not in the colorScheme
  }));

  const options = {
    chart: {
      id: "additional-bar-chart",
      type: "bar",
      toolbar: { show: false },
      animations: { enabled: true },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        barWidth: 20,
        dataLabels: {
          position: 'top',  
        },
      },
    },
    dataLabels: { enabled: false },
    xaxis: {
      categories: [selectedMonth],
      labels: {
        show: true,
        style: { colors: "white", fontSize: "12px", fontFamily: "'Nunito Sans', sans-serif" },
      },
    },
    yaxis: {
      labels: {
        show: true,
        style: { colors: "white", fontSize: "12px", fontFamily: "'Nunito Sans', sans-serif" },
      },
    },
    grid: { 
      borderColor: "#444", 
      xaxis: { lines: { show: false } }, 
      yaxis: { lines: { show: true } },
    },
    tooltip: { theme: "dark" },
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'center',
      fontSize: '12px',
      fontFamily: "'Nunito Sans', sans-serif",
      labels: {
        colors: 'white',
      },
    },
    colors: Object.values(colorScheme),
  };

  return (
    <div>
      <button onClick={handleBackClick} style={{ marginBottom: "5px", background:'transparent',color:'#fff',border:'none' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-left">
          <path d="m15 18-6-6 6-6"/>
        </svg>
      </button>
      <h4 style={{ color: "white", textAlign: "center", marginBottom: "10px" }}>
        Downtime Reasons for {selectedMonth}
      </h4>
      <Chart
        options={options}
        series={series}
        type="bar"
        height="348"
        aria-label={`Downtime Reasons for ${selectedMonth}`}
      />
    </div>
  );
};

export default AdditionalBarChart;
