import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import AdditionalBarChart from "./AdditionalBarChart";
import { useNavigate } from 'react-router-dom';
const colorScheme = {
  "Machine Break Down": "#FF4560",
  "No Manpower": "#00E396",
  "Plan Change": "#775DD0",
  "Trolley Issue": "#008FFB",
  "Power Failure": "#FEB019",
};

const MachineDowntimeChart = () => {
  const [forecastData, setForecastData] = useState([]);
  const [reasonData, setReasonData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null);

  const [selectedData, setSelectedData] = useState({});
  const [isMainChart, setIsMainChart] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchForecastData = async () => {
    
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(`${process.env.REACT_APP_LOCALHOST_API_LINK}/getMachineDownPrediction`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: JSON.stringify({ deptId: 1 })
        });
    
        if (!sessionStorage.getItem("msal.account.keys")) {
          console.log("Unauthorized access. Redirecting to login page...");
          navigate('/login'); // Redirect to the login page using navigate
          return;
        }
    
        const data = await response.json();
        setForecastData(data.forecast_month_wise);
        setReasonData(data.forecast_reason_wise);
      } catch (error) {
        console.error("Error fetching forecast data:", error);
      }
    };

    fetchForecastData();
  }, []);

  const months = forecastData.map(item => item.Month);
  const reasons = [...new Set(reasonData.map(item => item.Reason))];

  const downtimeData = months.map(month => {
    const dataForMonth = reasonData.filter(item => item.Month === month);
    const reasonCounts = reasons.map(reason => {
      const reasonItem = dataForMonth.find(item => item.Reason === reason);
      return reasonItem ? reasonItem.DowntimeCount : 0;
    });
    return {
      month,
      reasonCounts
    };
  });

  const handleBarClick = (event, chartContext, config) => {
    const monthIndex = config.dataPointIndex;
    const month = months[monthIndex];
    const dataForSelectedMonth = reasonData
      .filter(item => item.Month === month)
      .reduce((acc, curr) => {
        if (!acc[curr.Reason]) {
          acc[curr.Reason] = 0;
        }
        acc[curr.Reason] += curr.DowntimeCount;
        return acc;
      }, {});

    setSelectedMonth(month);
    setSelectedData(dataForSelectedMonth);
    setIsMainChart(false);
  };


  const handleBackClick = () => {
    setIsMainChart(true);
  };

  const options = {
    chart: {
      id: "machine-downtime-bar",
      fontFamily: '"Poppins", sans-serif',
      type: "bar",
      toolbar: { show: false },
      animations: { enabled: true },
      events: {
        dataPointSelection: handleBarClick,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
        endingShape: 'rounded',
      },
    },
    dataLabels: { enabled: false },
    xaxis: {
      categories: months,
      labels: {
        show: true,
        style: { colors: "white", fontSize: "12px", fontFamily: "'Nunito Sans', sans-serif" },
      },
    },
    yaxis: {
      labels: {
        show: true,
        style: { colors: "white", fontSize: "12px", fontFamily: "'Nunito Sans', sans-serif" },
      },
    },
    grid: { 
      borderColor: "#444", 
      xaxis: { lines: { show: false } }, 
      yaxis: { lines: { show: true } },
    },
    tooltip: { theme: "dark" },
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'center',
      fontSize: '12px',
      fontFamily: "'Nunito Sans', sans-serif",
      labels: {
        colors: 'white',
      },
    },
    colors: reasons.map(reason => colorScheme[reason]),
  };

  const series = reasons.map((reason, index) => ({
    name: reason,
    data: downtimeData.map(data => data.reasonCounts[index]),
  }));

  return (
    <div>
      {isMainChart ? (
        <>
          <h4 style={{ color: "white", textAlign: "center", marginBottom: "10px" }}>
            Machine Downtime Forecast for 2024
          </h4>
          <Chart
            options={options}
            series={series}
            type="bar"
            height="348"
            aria-label="Machine Downtime Chart for 2024"
          />
        </>
      ) : (
        <AdditionalBarChart 
          selectedMonth={selectedMonth} 
          additionalData={selectedData} 
          colorScheme={colorScheme} 
          handleBackClick={handleBackClick}
        />
      )}
    </div>
  );
};

export default MachineDowntimeChart;
