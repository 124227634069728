import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Chart from 'react-apexcharts';
import AdditionalBarChart from './AdditionalBarChart';

const colorScheme = {
  "Machine Break Down": "#FF4560",
  "No Manpower": "#00E396",
  "Plan Change": "#775DD0",
  "Trolley Issue": "#008FFB",
  "Power Failure": "#FEB019",
};

const MachineBreakdownLineChart = ({ predictions, rejections }) => {
  const navigate = useNavigate(); 
  const [selectedPoint, setSelectedPoint] = useState('');
  const [selectedData, setSelectedData] = useState({});
  const [isMainChart, setIsMainChart] = useState(true);
  const [forecastData, setForecastData] = useState([]);
  const [reasonData, setReasonData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null);

  useEffect(() => {
    const fetchForecastData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(`${process.env.REACT_APP_LOCALHOST_API_LINK}/getMachineDownPrediction`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: JSON.stringify({ deptId: 1 })
        });
        const data = await response.json();

        if (!sessionStorage.getItem("msal.account.keys")) {
          navigate("/login"); 
        } else if (response.ok) {
          setForecastData(data.forecast_month_wise);
          setReasonData(data.forecast_reason_wise);
        } else {
          console.error("Failed to fetch forecast data.");
        }
      } catch (error) {
        console.error("Error fetching forecast data:", error);
      }
    };

    fetchForecastData();

    if (!predictions || !rejections || !predictions.series || !rejections.series) {
      console.error('Error: Missing data for chart rendering');
    }
  }, [navigate, predictions, rejections]);

  const handleMarkerClick = async (event, chartContext, config) => {
    const dataPointIndex = config.dataPointIndex;
    const selectedMonth = predictions.labels[dataPointIndex];
    console.log('Selected Month:', selectedMonth);
    const nextYearMonth = getNextYearMonth(selectedMonth);
    console.log("nextmonth", nextYearMonth);
    const dataForSelectedMonth = reasonData
      .filter(item => item.Month === nextYearMonth)
      .reduce((acc, curr) => {
        if (!acc[curr.Reason]) {
          acc[curr.Reason] = 0;
        }
        acc[curr.Reason] += curr.DowntimeCount;
        return acc;
      }, {});
    console.log("data", dataForSelectedMonth);
    setSelectedMonth(nextYearMonth);
    setSelectedPoint(nextYearMonth);
    setSelectedData(dataForSelectedMonth);
    setIsMainChart(false);
  };

  const getNextYearMonth = (currentMonth) => {
    const [month, year] = currentMonth.split(' ');
    const nextYear = parseInt(year, 10) + 1;
    console.log('Current Month:', month, 'Current Year:', year, 'Next Year:', nextYear);
    return `${month} ${nextYear}`;
  };

  const handleBackClick = () => {
    console.log('Going back to main chart');
    setIsMainChart(true);
  };

  if (!predictions || !rejections || !predictions.series || !rejections.series) {
    return <div>Error: Missing data for chart rendering</div>;
  }

  const chartOptions = {
    chart: {
      id: 'machine-breakdown-line',
      fontFamily: '"Poppins", sans-serif',
      type: 'line',
      toolbar: { show: false },
      events: {
        markerClick: handleMarkerClick,
      },
    },
    dataLabels: { enabled: false },
    tooltip: { theme: 'dark' },
    markers: { size: 2, hover: { size: 5, sizeOffset: 2 } },
    stroke: { width: 3, curve: 'smooth', colors: ['#FFFF00', '#0066FF'] },
    colors: ['yellow', 'blue'],
    fill: { opacity: 1 },
    legend: { show: false },
    xaxis: {
      categories: predictions.labels,
      labels: {
        show: true,
        style: { colors: 'white', fontSize: '12px', fontFamily: "'Nunito Sans', sans-serif" },
      },
    },
    yaxis: {
      labels: {
        show: true,
        style: { colors: 'white', fontSize: '12px', fontFamily: "'Nunito Sans', sans-serif" },
      },
    },
    grid: { borderColor: '#444', xaxis: { lines: { show: false } }, yaxis: { lines: { show: true } } },
  };

  const chartSeries = [
    {
      name: 'Forecast',
      data: rejections.series[0] || [],
    },
    {
      name: 'Actual',
      data: predictions.series[0] || [],
    },
  ];

  return (
    <div>
      {isMainChart ? (
        <Chart
          options={chartOptions}
          series={chartSeries}
          type="line"
          height="350"
          aria-label="Machine Breakdown Line Chart"
        />
      ) : (
        <AdditionalBarChart
          selectedMonth={selectedPoint}
          additionalData={selectedData}
          colorScheme={colorScheme}
          handleBackClick={handleBackClick}
        />
      )}
    </div>
  );
};

export default MachineBreakdownLineChart;
