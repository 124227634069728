import { PublicClientApplication } from '@azure/msal-browser';

export const msalConfig = {
 

    // postLogoutRedirectUri: 'http://localhost:3000/home',
  // },
  auth: {
    clientId: 'dc456b44-7591-458e-91a1-5c06c3649c48',
    authority: 'https://login.microsoftonline.com/0fc269d1-a453-4436-a3c5-063e0500ec67',
    redirectUri:'https://dev.looquplab.com/home',
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ['User.Read'], // You can add other scopes needed for your application
};

// Initialize MSAL instance
export const msalInstance = new PublicClientApplication(msalConfig);

// Define the onRedirectCallback function
export const onRedirectCallback = (response) => {
  if (response) {
    window.history.replaceState({}, document.title, window.location.pathname);
    // Optionally, add your navigation logic here
  }
};

// Set the onRedirectCallback function
msalInstance.handleRedirectPromise().then(onRedirectCallback).catch((error) => {
  console.error('Redirect error:', error);
});
