import React from 'react'
//import WhatNew from './WhatNew'
import {W} from './W.tsx'

const Whats = () => {
  return (
    <div>
      <W/>
    </div>
  )
}

export default Whats
