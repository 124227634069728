import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { store } from './store/Store';
import App from './App';
import './data';
import { RecoilRoot } from 'recoil';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig'; 

const container = document.getElementById('root');
const root = createRoot(container);

const msalInstance = new PublicClientApplication(msalConfig);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      {/* <React.StrictMode> */}
        <MsalProvider instance={msalInstance}>
          <RecoilRoot>
            <App />
          </RecoilRoot>
        </MsalProvider>
      {/* </React.StrictMode> */}
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
