import React, { useState, useEffect } from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from "recharts";
import axios from 'axios';

const machineOptions = {
  Hegla: 1,
  "Double Edging": 3,
  Tenon: 4,
  Fabrication: 5,
  IG: 8,
  "Tech Glass": 9,
  "LM-Load": 10,
  "LM-Unload": 11,
  Dispatch: 12,
  UNI: 13,
  "North Glass": 14,
  Ashton: 15,
  "C Rapid": 17,
};

const months = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const LOPvsDowntime = () => {
  const [selectedMachine, setSelectedMachine] = useState("Hegla");
  const [selectedMonth, setSelectedMonth] = useState(0); // 
  const [selectedYear, setSelectedYear] = useState("2024");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ loadingError, setLoadingError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setLoadingError(null);

      try {
        const payload = {
          machine_name: selectedMachine,
          month: selectedMonth + 1, 
          year: parseInt(selectedYear)
        };

        const response = await axios.post(`${process.env.REACT_APP_LOCALHOST_API_LINK}/machine_prod_loss`, payload);
        const fetchedData = response.data.map(item => ({
          day: new Date(item.date).getDate(),
          loss: item.total_prod_loss
        }));
        setData(fetchedData);
      } catch (err) {
        setLoadingError('Failed to fetch data');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedMachine, selectedMonth, selectedYear]);

  return (
    <div style={{ padding: "20px" }}>
      <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "20px" }}>
        <div style={{ marginRight: "20px" }}>
          <label style={{ marginRight: "10px", fontSize: "16px" }}>
            Select Machine
            <select
              value={selectedMachine}
              onChange={(e) => setSelectedMachine(e.target.value)}
              style={{
                padding: "10px",
                fontSize: "16px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                marginLeft: "10px",
                outline: "none",
              }}
            >
              {Object.keys(machineOptions).map((machine) => (
                <option key={machine} value={machine}>
                  {machine}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div style={{ marginRight: "20px" }}>
          <label style={{ marginRight: "10px", fontSize: "16px" }}>
            Select Month
            <select
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(Number(e.target.value))}
              style={{
                padding: "10px",
                fontSize: "16px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                marginLeft: "10px",
                outline: "none",
              }}
            >
              {months.map((month, index) => (
                <option key={month} value={index}>
                  {month}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div>
          <label style={{ marginRight: "10px", fontSize: "16px" }}>
            Select Year
            <select
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
              style={{
                padding: "10px",
                fontSize: "16px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                marginLeft: "10px",
                outline: "none",
              }}
            >
              {[2020,2021,2022, 2023, 2024,].map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </label>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        {loading ? (
          <p>Loading...</p>
        ) : loadingError ? (
          <p>{loadingError}</p>
        ) : (
          <BarChart
            width={1000}
            height={500}
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="day"
              label={{
                value: `Days of ${months[selectedMonth]}`,
                position: "insideBottomCenter",
                offset: 0,
                dy: 12,
              }}
            />
            <YAxis
              label={{
                value: "Loss of Production",
                angle: -90,
                position: "insideLeft",
              }}
            />
            <Tooltip cursor={{ fill: "transparent" }} />
            <Bar dataKey="loss" fill="#c15377" barSize={10} />
          </BarChart>
        )}
      </div>
    </div>
  );
};

export default LOPvsDowntime;
