import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

const AuthRedirectHandler = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();

  useEffect(() => {
    instance.handleRedirectPromise()
      .then(response => {
        if (response) {
          console.log('Authentication response:', response);
          navigate('/home');
        }
      })
      .catch(error => {
        console.error('Redirect error:', error);
      });
  }, [instance]);

  return null;
};

export default AuthRedirectHandler;
