import React, { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import logo from "../../../assets/images/logos/Looqup.png";
import logo1 from "../../../assets/images/users/user4.jpg";
//import logo2 from "../../../assets/images/SCR-20240712-mayn.png";
import { Row, Col } from "reactstrap";
import "./Chat.css";
import ReactMarkdown from "react-markdown";
//import { log } from "console";
//import BarChart from './BarChart';


interface IMessage {
  id: string;
  name: string;
  type: string;
  output: string;
  createdAt: string;
}

interface ChartData {
  label: string;
  value: number;
}

function Chainlit() {
  const [inputValue, setInputValue] = useState("");
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [websocket, setWebsocket] = useState<WebSocket | null>(null);
  const [loading, setLoading] = useState(false);
  const [currentBotMessage, setCurrentBotMessage] = useState(""); // State for typing effect
  const messagesHistoryRef = useRef<HTMLDivElement>(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const [showRow, setShowRow] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // State to manage button disabled state
  const connectionStartTimeRef = useRef<number | null>(null); // Ref to store the connection start time
  const [loadingDots, setLoadingDots] = useState("..."); // State for interactive loading indicator
  const pingIntervalRef = useRef<number | null>(null); // Ref to store the ping interval ID
  const [initialQuestions, setInitialQuestions] = useState<string[]>([]); // State for initial questions
  const [similarQuestions, setSimilarQuestions] = useState<string[]>([]); // State for similar questions
  const [displaySimilarQuestions, setDisplaySimilarQuestions] = useState(false); // State for similar questions
  const [dynamicData, setDynamicData] = useState<ChartData[]>([]);
  const [xAxisLabel, setXAxisLabel] = useState("");
  const [yAxisLabel, setYAxisLabel] = useState("");

  useEffect(() => {
    if (firstLoad) {
      fetchInitialQuestions(); // Fetch initial questions on the first load

      // Initial message
      const initialMessage: IMessage = {
        id: uuidv4(),
        name: "bot",
        type: "bot_message",
        output: "What do you want to Looqup today?",
        createdAt: new Date().toISOString(),
      };
      setMessages([initialMessage]);

      setFirstLoad(false);
    }

    if (messagesHistoryRef.current) {
      messagesHistoryRef.current.scrollTop = messagesHistoryRef.current.scrollHeight;
    }
  }, [messages, firstLoad]);

  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        setLoadingDots((prevDots) => (prevDots.length < 3 ? prevDots + "." : "."));
      }, 500);
      return () => clearInterval(interval);
    }
  }, [loading]);

  const fetchInitialQuestions = async () => {
    try {
      const response = await fetch("https://dev.chat.looquplab.com/generate-initial-questions/", {
        method: "POST",
      });
      const data = await response.json(); // Parse response as JSON
      console.log("data", data);

      // Extract questions from the response JSON
      const questions = data.questions || []; 

      console.log("Q: ", questions);
      setInitialQuestions(questions); // Update state with fetched questions

    } catch (error) {
      console.error("Error fetching initial questions:", error);
    }
  };

  const handleSendMessage = () => {
    setShowRow(false);
    const content = inputValue.trim();

    if (content) {
      const userMessage: IMessage = {
        id: uuidv4(),
        name: "user",
        type: "user_message",
        output: content,
        createdAt: new Date().toISOString(),
      };

      setMessages((prevMessages) => [...prevMessages, userMessage]);
      setInputValue("");

      setLoading(true); // Start loading indicator

      // Fetch similar questions
      fetchSimilarQuestions(content);

      // Initialize WebSocket connection if not already connected
      if (!websocket || websocket.readyState === WebSocket.CLOSED) {
        const ws = new WebSocket(`${process.env.REACT_APP_CHAT_API_LINK}`);
        setWebsocket(ws);

        ws.onopen = () => {
          console.log("WebSocket connection established");
          connectionStartTimeRef.current = Date.now(); // Store the connection start time
          setIsButtonDisabled(true); // Disable the button when the WebSocket connection is established
          
          // Start ping interval
          pingIntervalRef.current = window.setInterval(() => {
            if (ws.readyState === WebSocket.OPEN) {
              ws.send(JSON.stringify({ type: "ping" }));
            }
          }, 4000);

          ws.onclose = () => {
            setCurrentBotMessage(""); // Reset the typing effect
            setIsButtonDisabled(false); // Enable the button when the WebSocket connection is closed
            setLoading(false); // Stop loading indicator

            // Clear ping interval
            if (pingIntervalRef.current !== null) {
              clearInterval(pingIntervalRef.current);
              pingIntervalRef.current = null;
            }

            console.log("WebSocket connection closed");

            if (connectionStartTimeRef.current !== null) {
              const connectionEndTime = Date.now();
              const connectionDuration = connectionEndTime - connectionStartTimeRef.current;
              console.log(`WebSocket connection duration: ${connectionDuration} ms`);
              connectionStartTimeRef.current = null; // Reset the start time
            }
          };

          // Send message to the WebSocket server once the connection is established
          ws.send(JSON.stringify({ query: content }));
        };

        ws.onmessage= (event) => {
          const message = JSON.parse(event.data);
          if (message.response) {
            console.log('msgres:', message.response);
            // Clear ping interval
            if (pingIntervalRef.current !== null) {
              clearInterval(pingIntervalRef.current);
              pingIntervalRef.current = null;
            }
            setLoading(false); // Stop loading indicator when response is received
            displayTypingEffect(message.response);
            
          
          } else if (message.plot){
            try {
              const plotData = JSON.parse(message.plot); // Parse plot data
              console.log('######', plotData.data[0]);

              const hoverTemplate = plotData.data[0].hovertemplate;
              console.log('hoverTemplate:', hoverTemplate); // Log the hovertemplate for debugging

      const xAxisLabelMatch = hoverTemplate.match(/([^=%]+)=%{x}/);
      const yAxisLabelMatch = hoverTemplate.match(/<br>([^<]*)=%{y}/);

      if (xAxisLabelMatch && yAxisLabelMatch) {
        const xAxisLabel = xAxisLabelMatch[1];
        const yAxisLabel = yAxisLabelMatch[1];

        console.log(`Extracted xAxisLabel: ${xAxisLabel}`);
        console.log(`Extracted yAxisLabel: ${yAxisLabel}`);
              const chartData: ChartData[] = plotData.data[0].x.map((label: string, index: number) => ({
                label,
                value: plotData.data[0].y[index],
              }));
              setDynamicData(chartData);
              setXAxisLabel(xAxisLabel);
              setYAxisLabel(yAxisLabel);
            }else {
              console.error('Failed to extract axis labels from hovertemplate');
            }
              
              
            } catch (error) {
              console.error('Error parsing plot data:', error);
            }
          }
          else if (message.message === "Query execution Ended Here") {
            setDisplaySimilarQuestions(true);
            console.log('%%%%%',message);
            ws.close();
          }

        };

        ws.onerror = (error) => {
          console.error("WebSocket error:", error);
          setLoading(false); // Stop loading indicator on error
          setIsButtonDisabled(false); // Enable the button on error

          // Clear ping interval
          if (pingIntervalRef.current !== null) {
            clearInterval(pingIntervalRef.current);
            pingIntervalRef.current = null;
          }
        };
      } else {
        // Send message to the WebSocket server if already connected
        websocket.send(JSON.stringify({ query: content }));
        setIsButtonDisabled(true); // Disable the button when sending the message
      }
    }
  };

  const fetchSimilarQuestions = async (query: string) => {
    try {
      const response = await fetch("https://dev.chat.looquplab.com/generate-questions/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query }),
      });
      const data = await response.json();
      setSimilarQuestions(data.questions || []);
      console.log('similar que:',similarQuestions);
      
    } catch (error) {
      console.error("Error fetching similar questions:", error);
    }
  };

  const displayTypingEffect = (word: string) => {
    setCurrentBotMessage((prev) => {
      const newMessage = prev + word;
      setMessages((prevMessages) => {
        const lastMessage = prevMessages[prevMessages.length - 1];

        if (lastMessage && lastMessage.name === "bot" && lastMessage.type === "bot_message") {
          // If the last message is a bot message, update its output
          const updatedMessages = [...prevMessages];
          updatedMessages[updatedMessages.length - 1] = {
            ...lastMessage,
            output: newMessage.trim(),
          };
          console.log("Updated Messages:", updatedMessages);
          return updatedMessages;
        } else {
          // Otherwise, add a new bot message
          const newMessages = [
            ...prevMessages,
            {
              id: uuidv4(),
              name: "bot",
              type: "bot_message",
              output: newMessage.trim(),
              createdAt: new Date().toISOString(),
            },
          ];
          console.log("New Messages:", newMessages);
          return newMessages;
        }
      });
      return newMessage;
    });
  };

  const renderMessage = (message: IMessage) => {
    const shouldShowChart = dynamicData.length > 0 && message.output !== 'What do you want to Looqup today?';
    return (
      <div key={message.id} className={`message ${message.name === 'user' ? 'me' : ''}`}>
        <div
          className='message-body'
          style={{
            backgroundColor: message.name === 'user' ? '#FFFF' : '#180227',
            display: 'inline-block',
            padding: '12px',
            borderRadius: '6px',
            maxWidth: '600px',
            minWidth: '75px',
            lineHeight: '20px',
            textAlign: 'initial',
          }}
        >
          {message.name === 'user' ? (
            <>
              <img
                src={logo1}
                alt=''
                style={{
                  width: '30px',
                  backgroundColor: 'black',
                  borderRadius: '8px',
                  color: 'black',
                  marginTop: '10px',
                }}
              />{' '}
              <br />
              <br />
              {message.output}
            </>
          ) : (
            <div style={{ padding: '20px' }}>
              <img
                src={logo}
                alt=''
                style={{
                  width: '80px',
                  borderRadius: '20px',
                  backgroundColor: 'black',
                  color: 'white',
                }}
              />{' '}
              <br />
              <span></span> <br />
              <ReactMarkdown>{message.output}</ReactMarkdown>
              
              {shouldShowChart && <BarChart data={dynamicData} xAxisLabel={xAxisLabel} yAxisLabel={yAxisLabel} />}
            </div>
          )}
          <br />
        </div>
      </div>
    );
  };

  return (
    <div className="app">
      <div className="messages" style={{ backgroundColor: "#2F2F57", padding: "10px" }}>
        <div className="messages-history" ref={messagesHistoryRef}>
          {messages.map((message) => renderMessage(message))}

          {loading && (
            <div className="message">
              <div
                className="message-body loading-indicator"
                style={{
                  backgroundColor: "#180227",
                  display: "inline-block",
                  padding: "12px",
                  borderRadius: "6px",
                  maxWidth: "600px",
                  minWidth: "75px",
                  lineHeight: "20px",
                  textAlign: "initial",
                }}
              >
                <img
                  src={logo}
                  alt=""
                  style={{
                    width: "80px",
                    borderRadius: "20px",
                    backgroundColor: "black",
                    color: "white",
                  }}
                />{" "}
                <br />
                <span style={{ fontWeight: "bold" }}>Processing{loadingDots}</span>
              </div>
            </div>
          )}
        </div>
        {showRow && (
          <Row
            className="mb-2 m-1"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {initialQuestions.length > 0 && initialQuestions.map((question, index) => (
              <Col
                key={index}
                md={4}
                className="ml-2 mb-2 m-1"
                style={{
                  backgroundColor: "#515175",
                  borderRadius: "10px",
                  color: "white",
                  cursor: "pointer",
                }}
                onClick={() => setInputValue(question)}
              >
                <p style={{ marginTop: "10px", fontSize: "14px" }}>
                  {question}
                </p>
              </Col>
            ))}
          </Row>
        )}
        

        <form
          className=""
          onSubmit={(e) => {
            e.preventDefault();
            handleSendMessage();
          }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ position: "relative", display: "inline-block" }}>
            <input
              className="btns"
              type="text"
              placeholder="What's next?"
              onChange={(e) => setInputValue(e.target.value)}
              value={inputValue}
            />
            <button
              className="btn btn-primary"
              style={{
                position: "absolute",
                top: 17,
                color: "white",
                borderRadius: "50%",
                backgroundColor: "#7A00CC",
                right: 35,
                height: "39%",
                width: "35px",
              }}
              onClick={handleSendMessage}
              disabled={isButtonDisabled}
            >
              ➔
            </button>
          </div>
        </form>
        {similarQuestions.length > 0 && displaySimilarQuestions && (
          <Row
            className="mb-2 m-1"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {similarQuestions.map((question, index) => (
              <Col
                key={index}
                md={4}
                className="ml-2 mb-2 m-1"
                style={{
                  backgroundColor: "#515175",
                  borderRadius: "10px",
                  color: "white",
                  cursor: "pointer",
                }}
                onClick={() => setInputValue(question)}
              >
                <p style={{ marginTop: "10px", fontSize: "14px" }}>
                  {question}
                </p>
              </Col>
            ))}
          </Row>
        )}
      </div>
    </div>
  );
}

export { Chainlit };
