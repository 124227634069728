import React from 'react' ;

const Container2 =({ children}) => {
    const containerStyle = {
        padding: '20px',
        margin: '50px',
        maxWidth: '1200px',
        backgroundColor: 'rgb(47, 47, 87)',
        height: '500px'

    };

    return <div style={containerStyle}>{children}</div>
};
export default Container2;