import React, { useState, useEffect } from "react";
import { Tabs, Tab, Paper } from "@mui/material";
import DashCard from "../../components/dashboard/dashboardCards/DashCard";
import { Row, Col, Modal, ModalBody } from "reactstrap";
import Chart from "react-apexcharts";
import moment from "moment";
import * as Icon from "react-feather";
import logo from "../../assets/images/logos/Looqup.png";
import { Chainlit } from "../../views/apps/chat/Chainlit.tsx";
import MachineDowntimeChart from "./MachineDowntimeChart";
import AdditionalBarChart from "./AdditionalBarChart.js";
import LineChart from "./LineChart.js";
import RejectionsLineChart from "./RejectionsLineChart.js";
import MachineBreakdownLineChart from "./MachineBreakdownLineChart.js";
import "./customApexCharts.css";

function Predictions(props) {
  const [tabValue, setTabValue] = useState(0);
  const [machine, setMachine] = useState(props.customProp);
  const [selectedMachine, setSelectedMachine] = useState("Hegla");
  const [value, setValue] = useState("Hegla");
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [additionalData, setAdditionalData] = useState({});
  const [selectedDowntimeData, setSelectedDowntimeData] = useState(null);

  const [forecast, setForecast] = useState({ labels: [], series: [] });
  const [actual, setActual] = useState({ labels: [], series: [] });
  const [workOrderCount, setWorkOrderCount] = useState({ labels: [], series: [] }); 
  const[machforecast,setMachForecast]=useState({labels: [], series: []});
  const[machactual,setMachActual]=useState({labels: [], series: []});

  const [isOpen, setIsOpen] = useState(false);
  const 
  toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const handleTabChange = (event, newValue) => setTabValue(newValue);

  const fetchWorkOrderCount = async () => {
    try {
        const token = localStorage.getItem('token');
        const deptId = getDeptIdFromName(selectedMachine);

        const response = await fetch(`${process.env.REACT_APP_LOCALHOST_API_LINK}/getWorkorderPrediction`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({ deptId })
        });

        if (!sessionStorage.getItem("msal.account.keys") ){
            console.log("Authentication failed. Please log in again.");
            // Handle token expiration or invalid token scenario, e.g., redirect to login page
            return;
        }

        const data = await response.json();

        if (response.ok) {
            const workOrderData = data.workorder_count.filter(item => !['January 2024', 'February 2024', 'March 2024'].includes(item.Month));
            const machineDownData = data.machine_down_count;

            const workOrderLabels = workOrderData.map(item => item.Month);
            const workOrderSeries = workOrderData.map(item => item.Forecast);

            const machineDownLabels = machineDownData.map(item => item.Month);
            const machineDownSeries = machineDownData.map(item => item.DowntimeCount);

            setWorkOrderCount({
                labels: workOrderLabels,
                series: [workOrderSeries, machineDownSeries]
            });
        } else {
            console.log("Error fetching work order count data:", data.message);
        }
    } catch (error) {
        console.log("Error fetching work order count data:", error);
    }
};


  useEffect(() => {
    setMachine(props.customProp);
    setValue(selectedMachine);
    fetchPredictions();
    fetchWorkOrderCount();
    fetchMachineDown();
}, [machine, selectedMachine, props.customProp]);


  const handleMachineChange = (event) => {
    const machine = event.target.value;
    setSelectedMachine(machine);

    const fetchAdditionalData = async (month) => {
      try {
        const dummyData = {
          "Job Change": 25,
          "No Manpower": 30,
          "No Operator": 15,
          "No Consumable Material": 40,
          "Plan Change": 20,
        };
        setAdditionalData(dummyData);
      } catch (error) {
        console.log("Error fetching additional data:", error);
      }
    };

    if (machine === "Rapid") {
      setValue("C Rapid");
    } else if (machine === "North") {
      setValue("North Glass");
    } else if (machine === "LamiUnload") {
      setValue("LM-Unload");
    } else if (machine === "LamiLoad") {
      setValue("LM-Load");
    } else {
      setValue(machine);
    }
  };

  const fetchPredictions = async () => {
    try {
      const deptId = getDeptIdFromName(selectedMachine);
      const token = localStorage.getItem("token");
      const response = await fetch(`${process.env.REACT_APP_LOCALHOST_API_LINK}/getRejectionPrediction`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({ deptId })
      });
  
      if (!response.ok) {
        if (!sessionStorage.getItem("msal.account.keys") ) {
          const errorData = await response.json();
          console.log('error:',errorData);
          if (errorData.detail === "Token mismatch") {
            localStorage.removeItem("token"); 
            window.location.href = "/login"; 
            return;
          }
        }
        throw new Error("Failed to fetch data");
      }
  
      const responseData = await response.json();
  
      const filteredEntries = responseData.actual_rejection_count;
      const filteredRejectionCounts = responseData.forecast;
  
      setForecast({
        labels: filteredEntries.map((item) => item.Month),
        series: [filteredEntries.map((item) => item.TotalRejections.toFixed(0))]
      });
      setActual({
        labels: filteredRejectionCounts.map((item) => item.Month),
        series: [filteredRejectionCounts.map((item) => item.Forecast.toFixed(0))]
      });
    } catch (error) {
      console.log(error, "Error Loading!!");
    }
  };
  

  const fetchMachineDown = async () => {
    try {
      const deptId = getDeptIdFromName(selectedMachine);
      const token = localStorage.getItem("token");
      const response = await fetch(`${process.env.REACT_APP_LOCALHOST_API_LINK}/getMachineDownPrediction`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({ deptId })
      });
  
      if (!response.ok) {
        if (!sessionStorage.getItem("msal.account.keys") ) {
          const errorData = await response.json();
          if (errorData.detail === "Token mismatch ") {
            localStorage.removeItem("token"); 
            window.location.href = "/login";
            return;
          }
        }
        throw new Error("Failed to fetch data");
      }
  
      const responseData = await response.json();
  
      const filteredEntries = responseData.actual_machine_down_count;
      const filteredRejectionCounts = responseData.forecast_month_wise;
  
      setMachForecast({
        labels: filteredRejectionCounts.map((item) => item.Month),
        series: [filteredRejectionCounts.map((item) => parseFloat(item.DowntimeCount.toFixed(0)))]
      });
  
      setMachActual({
        labels: filteredEntries.map((item) => item.month_year),
        series: [filteredEntries.map((item) => parseFloat(item.entry_count.toFixed(0)))]
      });
    } catch (error) {
      console.log(error, "Error Loading!!");
    }
  };
  

  const getDeptIdFromName = (deptName) => {
    switch (deptName) {
      case "Hegla":
        return 1;
      case "Double Edging":
        return 3;
      case "Tenon":
        return 4;
      case "Fabrication":
        return 5;
      case "IG":
        return 8;
      case "Tec Glass":
        return 9;
      case "LM-Load":
        return 10;
      case "LM-Unload":
        return 11;
      case "Dispatch":
        return 12;
      case "UNI":
        return 13;
      case "North Glass":
        return 14;
      case "Ashton":
        return 15;
      case "C Rapid":
        return 17;
      case "Washing":
        return 19;
      case "Maintainance":
        return 20;
      case "Gel":
        return 22;
      default:
        return null;
    }
  };

  const handleDowntimeMonthClick = (month, downtimeData) => {
    setSelectedDowntimeData({ month, data: downtimeData });
  };

  return (
    <Row style={{ marginTop: "20px" }}>
      <Col md={12}>
        <Paper style={{ backgroundColor: "#2F2F57", color: "white", padding: "20px" }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Rejections" style={{ color: "white" }} />
            <Tab label="Machine Breakdown" style={{ color: "white" }} />
            <Tab label="Work Order" style={{ color: "white" }} />
          </Tabs>
          <div style={{ marginTop: "20px" }}>
            {tabValue === 0 && (
              <RejectionsTab
                rejections={forecast}
                predictions={actual}
                selectedMachine={selectedMachine}
                handleMachineChange={handleMachineChange}
                toggleModal={toggleModal}
                isOpen={isOpen}
                handleChartMarkClick={handleDowntimeMonthClick}
              />
            )}
            {tabValue === 1 && (
              <MachineBreakdownTab
                rejections={machforecast}
                predictions={machactual}
                selectedMachine={selectedMachine}
                handleMachineChange={handleMachineChange}
                toggleModal={toggleModal}
                isOpen={isOpen}
                handleChartMarkClick={handleDowntimeMonthClick}
              />
            )}
            {tabValue === 2 && (
              <WorkOrderCountTab
                workOrderCount={workOrderCount}
                selectedMachine={selectedMachine}
                toggleModal={toggleModal}
                isOpen={isOpen}
                handleMachineChange={handleMachineChange}
              />
            )}
          </div>
        </Paper>
      </Col>
    </Row>
  );
}

const RejectionsTab = ({
  rejections,
  predictions,
  selectedMachine,
  handleMachineChange,
  toggleModal,
  isOpen,
  handleChartMarkClick,
}) => (
  <>
    <Col style={{ display: "flex", padding: "10px", marginTop: "20px" }}>
      <div>
        <select
          value={selectedMachine}
          onChange={handleMachineChange}
          style={{
            padding: "5px",
            fontSize: "16px",
            borderRadius: "5px",
            backgroundColor: "#f8f8f8",
            color: "#333",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            cursor: "pointer",
            border: "none",
            marginRight: "20px",
            outline: "none",
          }}
        >
          <option value="Hegla">Hegla</option>
          <option value="Double Edging">Double Edging</option>
          <option value="Tenon">Tenon</option>
          <option value="Fabrication">Fabrication</option>
          <option value="IG">IG</option>
          <option value="Tec Glass">Tech Glass</option>
          <option value="LamiLoad">Lami-Load</option>
          <option value="LamiUnload">Lami-Unload</option>
          <option value="UNI">UNI</option>
          <option value="North">North Glass</option>
          <option value="Ashton">Ashton</option>
          <option value="C Rapid">C Rapid</option>
        </select>
      </div>
    </Col>
    <Row>
      <Col md="12">
        <DashCard>
          <h4 style={{ fontWeight: "600" }}>Actual and Forecast</h4>
          <RejectionsLineChart predictions={predictions} rejections={rejections} />
        </DashCard>
      </Col>
    </Row>
    <div
        style={{
          width: "130px",
          height: "30px",
          padding: "20px",
          backgroundColor: "#2F2F57",
          position: "fixed",
          bottom: "15px",
          right: "17px",
          zIndex: 9999,
          borderRadius: "10px",
          cursor: "pointer",
          display: "flex",
        }}
        onClick={toggleModal}
      >
        <Icon.MessageCircle
          style={{
            position: "fixed",
            bottom: "20px",
            right: "115px",
            zIndex: 9999,
            height: "2em",
          }}
        />
        <img
          src={logo}
          alt=""
          style={{
            position: "fixed",
            bottom: "21px",
            right: "20px",
            zIndex: 9999,
            height: "2em",
          }}
        />
      </div>
      <Modal isOpen={isOpen} toggle={toggleModal} size="xl">
        <ModalBody style={{ backgroundColor: "#2F2F57" }}>
          <Chainlit />
        </ModalBody>
      </Modal>
  </>
);

const MachineBreakdownTab = ({
  rejections,
  predictions,
  selectedMachine,
  handleMachineChange,
  toggleModal,
  isOpen,
  handleChartMarkClick,
}) => (
  <>
    <Col style={{ display: "flex", padding: "10px", marginTop: "20px" }}>
      <div>
        <select
          value={selectedMachine}
          onChange={handleMachineChange}
          style={{
            padding: "5px",
            fontSize: "16px",
            borderRadius: "5px",
            backgroundColor: "#f8f8f8",
            color: "#333",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            cursor: "pointer",
            border: "none",
            marginRight: "20px",
            outline: "none",
          }}
        >
          <option value="Hegla">Hegla</option>
          <option value="Double Edging">Double Edging</option>
          <option value="Tenon">Tenon</option>
          <option value="Fabrication">Fabrication</option>
          <option value="IG">IG</option>
          <option value="Tec Glass">Tech Glass</option>
          <option value="LamiLoad">Lami-Load</option>
          <option value="LamiUnload">Lami-Unload</option>
          <option value="UNI">UNI</option>
          <option value="North">North Glass</option>
          <option value="Ashton">Ashton</option>
          <option value="C Rapid">C Rapid</option>
        </select>
      </div>
    </Col>
    <Row>
      <Col md="12">
        <DashCard>
          <h4 style={{ fontWeight: "600" }}>Breakdown</h4>
          <MachineBreakdownLineChart predictions={predictions} rejections={rejections} onMarkClick={handleChartMarkClick} />
          <MachineDowntimeChart onMonthClick={handleChartMarkClick} />
        </DashCard>
      </Col>
    </Row>
    <div
        style={{
          width: "130px",
          height: "30px",
          padding: "20px",
          backgroundColor: "#2F2F57",
          position: "fixed",
          bottom: "15px",
          right: "17px",
          zIndex: 9999,
          borderRadius: "10px",
          cursor: "pointer",
          display: "flex",
        }}
        onClick={toggleModal}
      >
        <Icon.MessageCircle
          style={{
            position: "fixed",
            bottom: "20px",
            right: "115px",
            zIndex: 9999,
            height: "2em",
          }}
        />
        <img
          src={logo}
          alt=""
          style={{
            position: "fixed",
            bottom: "21px",
            right: "20px",
            zIndex: 9999,
            height: "2em",
          }}
        />
      </div>
      <Modal isOpen={isOpen} toggle={toggleModal} size="xl">
        <ModalBody style={{ backgroundColor: "#2F2F57" }}>
          <Chainlit />
        </ModalBody>
      </Modal>
  </>
);


const WorkOrderCountTab = ({
  workOrderCount,
  selectedMachine,
  toggleModal,
  isOpen,
  handleMachineChange,
}) => (
  <>
    <Col style={{ display: "flex", padding: "10px", marginTop: "20px" }}>
      <div>
        <select
          value={selectedMachine}
          onChange={handleMachineChange}
          style={{
            padding: "5px",
            fontSize: "16px",
            borderRadius: "5px",
            backgroundColor: "#f8f8f8",
            color: "#333",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            cursor: "pointer",
            border: "none",
            marginRight: "20px",
            outline: "none",
          }}
        >
          <option value="Hegla">Hegla</option>
          <option value="Double Edging">Double Edging</option>
          <option value="Tenon">Tenon</option>
          <option value="Fabrication">Fabrication</option>
          <option value="IG">IG</option>
          <option value="Tec Glass">Tech Glass</option>
          <option value="LamiLoad">Lami-Load</option>
          <option value="LamiUnload">Lami-Unload</option>
          <option value="UNI">UNI</option>
          <option value="North">North Glass</option>
          <option value="Ashton">Ashton</option>
          <option value="C Rapid">C Rapid</option>
        </select>
      </div>
    </Col>
    <Row>
      <Col md="12">
        <DashCard>
          <h4 style={{ fontWeight: "600" }}>Work Order and Machine Downtime</h4>
          <Chart
            options={{
              chart: { id: "work-order-downtime" },
              xaxis: { 
                categories: workOrderCount.labels,
                labels: {
                  style: {
                    colors: '#FFFFFF',
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif'
                  }
                }
              },
              yaxis: {
                labels: {
                  style: {
                    colors: '#FFFFFF',
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif'
                  }
                }
              },
              legend: {
                labels: {
                  colors: '#FFFFFF',
                  fontSize: '14px',
                  position: 'top',
                  fontFamily: 'Helvetica, Arial, sans-serif'
                }
              },
              tooltip: {
                theme: 'dark',
                style: {
                  fontSize: '12px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  color: '#000000'
                },
                onDatasetHover: {
                  highlightDataSeries: true,
                },
              },
              dataLabels: {
                style: {
                  colors: ['#FFFFFF'],
                }
              },
            }}
            
            
            series={[
              {
                name: "Work Order",
                type: "bar",
                data: workOrderCount.series[0]
              },
              {
                name: "Machine Downtime",
                type: "line",
                data: workOrderCount.series[1]
              }
            ]}
            height="350"
          />
        </DashCard>
      </Col>
    </Row>
    <div
        style={{
          width: "130px",
          height: "30px",
          padding: "20px",
          backgroundColor: "#2F2F57",
          position: "fixed",
          bottom: "15px",
          right: "17px",
          zIndex: 9999,
          borderRadius: "10px",
          cursor: "pointer",
          display: "flex",
        }}
        onClick={toggleModal}
      >
        <Icon.MessageCircle
          style={{
            position: "fixed",
            bottom: "20px",
            right: "115px",
            zIndex: 9999,
            height: "2em",
          }}
        />
        <img
          src={logo}
          alt=""
          style={{
            position: "fixed",
            bottom: "21px",
            right: "20px",
            zIndex: 9999,
            height: "2em",
          }}
        />
      </div>
    <Modal isOpen={isOpen} toggle={toggleModal} size="xl">
        <ModalBody style={{ backgroundColor: "#2F2F57" }}>
          <Chainlit />
        </ModalBody>
      </Modal>
  </>
);


export default Predictions;
