import React from "react";
import HomepageCss from "./Homepage.module.css";
import logo from "../../assets/images/logos/Looqup.png";
import { Link } from "react-router-dom";
import { useState } from "react";

const Homepage = () => {
  const [isDivVisible, setIsDivVisible] = useState(false);
  const [type, setType] = useState("Select");
  const [backgroundC, setBackgrounC] = useState("rgba(255, 255, 255, 0.2)");
  const [colorC, setcolorC] = useState("rgba(255, 255, 255, 0.2");
  const [imageHide, setImageHide] = useState(true);
  const [showAssumptions, setshowAssumptions] = useState(false);
  const [searchColor, setSearchColor] = useState("#515175");
  const [showButton, setShowButton] = useState(false);
  const [inputFieldSet, setInputField] = useState("");
  const [pllacc, setPlaceholder] = useState("What do you want to Looqup today?");
  const [newChat, setNewChat] = useState(false);

  const handleSelectClick = () => {
    setIsDivVisible(!isDivVisible);
  };

  const handlePersonaSelect = (persona) => {
    setType(persona);
    setIsDivVisible(false);
    setBackgrounC("white");
    setcolorC("black");
    setImageHide(false);
    setshowAssumptions(true);
    setSearchColor("white");
    setShowButton(true);
    localStorage.setItem("persona", persona);
  };

  return (
    <>
      <div className="container-fluid" style={{ backgroundImage: `url(${require('../../assets/images/logos/1.webp')})`,backgroundRepeat:'no-repeat',backgroundSize:'cover' }}>
        <div className="container min-vh-100 d-flex justify-content-center align-items-center">
          <div className="col-xxl-10 col-xl-10 col-lg-8 col-md-12 col-sm-12">
            <div className="mb-4 d-flex justify-content-center">
              <img
                src={logo}
                alt=""
                className="logoImg"
                style={{ maxWidth: "270px", maxHeight: "270px" }}
              />
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <h1>Welcome to Looqup!</h1>
            </div>
            <div className={HomepageCss.selectDiv}>
              <p className={HomepageCss.imATag}>I'm a</p>
              <div
                className={HomepageCss.selectCss}
                onClick={handleSelectClick}
                style={{ backgroundColor: backgroundC }}
              >
                <p style={{ textAlign: "start", color: colorC }}>{type}</p>
                <span className={HomepageCss.arrow}></span>
              </div>
            </div>

            <div style={{ height: "178px", display: "flex", justifyContent: "center", marginLeft: "55px", marginTop: "20px", cursor: "pointer" }}>
              {isDivVisible && (
                <div className={HomepageCss.professionDiv}>
                  <div>
                    <Link to="/homepage" className={HomepageCss.linkunderline}>
                      <h6 className={HomepageCss.professionsStyle} onClick={() => handlePersonaSelect("Production Manager")}>
                        Production Manager
                      </h6>
                    </Link>
                    <Link to="/homepage" className={HomepageCss.linkunderline}>
                      <h6 className={HomepageCss.professionsStyle} onClick={() => handlePersonaSelect("Quality Analyst")}>
                        Quality Analyst
                      </h6>
                    </Link>
                    <Link to="/homepage" className={HomepageCss.linkunderline}>
                      <h6 className={HomepageCss.professionsStyle} onClick={() => handlePersonaSelect("Executive")}>
                        Executive
                      </h6>
                    </Link>
                    <Link to="/homepage" className={HomepageCss.linkunderline}>
                      <h6 className={HomepageCss.professionsStyle} onClick={() => handlePersonaSelect("Customer Relationship Manager")}>
                        Customer Relationship Manager
                      </h6>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Homepage;
