import React from 'react';
import Chart from 'react-apexcharts';

const RejectionsLineChart = ({ predictions, rejections }) => {
  if (!predictions || !rejections || !predictions.series || !rejections.series) {
    return <div>Error: Missing data for chart rendering</div>;
  }

  const chartOptions = {
    chart: {
      id: 'rejections-line',
      fontFamily: '"Poppins", sans-serif',
      type: 'line',
      toolbar: { show: false },
    },
    dataLabels: { enabled: false },
    tooltip: { theme: 'dark' },
    markers: { size: 2, hover: { size: 5, sizeOffset: 2 } },
    stroke: { width: 3, curve: 'smooth', colors: ['#FFFF00', '#0066FF'] },
    colors: ['yellow', 'blue'],
    fill: { opacity: 1 },
    legend: { show: false },
    xaxis: {
      categories: predictions.labels,
      labels: {
        show: true,
        style: { colors: 'white', fontSize: '12px', fontFamily: "'Nunito Sans', sans-serif" },
      },
    },
    yaxis: {
      labels: {
        show: true,
        style: { colors: 'white', fontSize: '12px', fontFamily: "'Nunito Sans', sans-serif" },
      },
    },
    grid: { borderColor: '#444', xaxis: { lines: { show: false } }, yaxis: { lines: { show: true } } },
  };

  const chartSeries = [
    {
      name: 'Forecast',
      data: predictions.series[0] || [],
    },
    {
      name: 'Actual',
      data: rejections.series[0] || [],
    },
  ];

  return (
    <div>
      <Chart
        options={chartOptions}
        series={chartSeries}
        type="line"
        height="350"
        aria-label="Predictions and Rejections Line Chart"
      />
    </div>
  );
};

export default RejectionsLineChart;
