import React from 'react'

const CRMAnalysis = () => {
  return (
    <div style={{padding:'20px'}}>
        <p>CRM Analytics In Progress</p>
    </div>
  )
}

export default CRMAnalysis
