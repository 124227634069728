import React, { useState, useRef, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import logo from "../../../assets/images/logos/Looqup.png";
import logo1 from "../../../assets/images/users/user4.jpg";

import "./Chat.css";
import ReactMarkdown from "react-markdown";

interface IMessage {
  id: string;
  name: string;
  type: string;
  output: string;
  createdAt: string;
}

function TG1({nameSpaceName}) {
    console.log(nameSpaceName,"NameSpaceName Given")
  const [inputValue, setInputValue] = useState("");
  const [messages, setMessages] = useState<IMessage[]>([]);
  const messagesHistoryRef = useRef(null);

  const [firstLoad, setFirstLoad] = useState(true);
  const [showRow, setShowRow] = useState(true);

  useEffect(() => {
    
    if (firstLoad) {
      // Initial message
      const initialMessage: IMessage = {
        id: uuidv4(),
        name: "me",
        type: "user_message",
        output: "Ask about your Data ?",
        createdAt: new Date().toISOString(),
      };
      setMessages([initialMessage]);

      setFirstLoad(false);
    }

    if (messagesHistoryRef.current) {
      messagesHistoryRef.current.scrollTop =
        messagesHistoryRef.current.scrollHeight;
    }
  }, [messages, firstLoad]);

  const handleSendMessage = async () => {
    setShowRow(false);
    const content = inputValue.trim();
  
    if (content) {
      const userMessage: IMessage = {
        id: uuidv4(),
        name: "user",
        type: "user_message",
        output: content,
        createdAt: new Date().toISOString(),
      };
      
      setMessages((prevMessages) => [...prevMessages, userMessage]);
      setInputValue("");
  
      const fetchingMessage: IMessage = {
        id: uuidv4(),
        name: "me",
        type: "bot_message",
        output: "Fetching data...",
        createdAt: new Date().toISOString(),
      };
      
      setMessages((prevMessages) => [...prevMessages, fetchingMessage]);
  
      try {
        const response = await fetch("https://looquplab.com:8000/queryFile/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ query: content,namespace_name:nameSpaceName }),
        });
  
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
  
        const data = await response.json();
        console.log(data, "Data");
        // const res = data.response;
        // console.log(res,"response Received in data")
        const botMessage: IMessage = {
          id: uuidv4(),
          name: "me",
          type: "bot_message",
          output: data.searchResults,
          createdAt: new Date().toISOString(),
        };
  
        // Replace the fetching message with the actual response
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.id === fetchingMessage.id ? botMessage : msg
          )
        );
      } catch (error) {
        console.error("Error sending message:", error);
        const errorMessage: IMessage = {
          id: uuidv4(),
          name: "me",
          type: "bot_message",
          output: "There was an error. Can you please retry?",
          createdAt: new Date().toISOString(),
        };
  
        setMessages((prevMessages) => [...prevMessages, errorMessage]);
  
        const previousQuestion: string = messages[messages.length - 1]?.output || "";
      }
    }
  };
  
  

  const renderMessage = (message: IMessage) => {
    return (
      <div
        key={message.id}
        className={`message ${message.name === "user" ? "me" : ""}`}
      >
        <div
          className="message-body"
          style={{
            backgroundColor: message.name === "user" ? "#FFFF" : "#180227",
            display: "inline-block",
            padding: "12px",
            borderRadius: "6px",
            maxWidth: "600px",
            minWidth: "75px",
            lineHeight: "20px",
            textAlign: "initial",
          }}
        >
          {message.name === "user" ? (
            <>
              <img
                src={logo1}
                alt=""
                style={{
                  width: "30px",
                  backgroundColor: "black",
                  borderRadius: "8px",
                  color: "black",
                  marginTop: "10px",
                }}
              />{" "}
              <br />
              <br />
              {message.output}
            </>
          ) : (
            <>
              <img
                src={logo}
                alt=""
                style={{
                  width: "80px",
                  borderRadius: "20px",
                  backgroundColor: "black",
                  color: "white",
                }}
              />{" "}
              <br />
              <span></span> <br />
              {console.log(message.output)}
              <ReactMarkdown>{message.output}</ReactMarkdown>
            </>
          )}
          <br />
        </div>
      </div>
    );
  };

  return (
    <div className="app">
      <div
        className="messages"
        style={{ backgroundColor: "#2F2F57", padding: "10px" }}
      >
        <div className="messages-history" ref={messagesHistoryRef}>
          {messages.map((message) => renderMessage(message))}
        </div>
        {showRow && (
          <>
            {" "}
        
          </>
        )}

        <form
          className=""
          onSubmit={(e) => {
            e.preventDefault();
            handleSendMessage();
          }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ position: "relative", display: "inline-block" }}>
            <input
              className="btns"
              type="text"
              placeholder="What's next?"
              onChange={(e) => setInputValue(e.target.value)}
              value={inputValue}
            />
            <button
              className="btn btn-primary"
              style={{
                position: "absolute",
                top: 17,
                color: "white",
                borderRadius: "50%",
                backgroundColor: "#9900ff",
                right: 35,
                height: "39%",
                width: "35px",
              }}
              onClick={handleSendMessage}
            >
              ➔
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export { TG1 };
