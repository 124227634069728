
import React, { useEffect, useState, useRef, act } from "react";

import { Modal, ModalBody } from "reactstrap";
import { Chainlit } from "../../views/apps/chat/Chainlit.tsx";


import {  } from "@mui/material";
import * as Icon from "react-feather";
import logo from "../../assets/images/logos/Looqup.png";
import Container from './Container.js';
import Container2 from './Container2.js'
function NewPage() {
 
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
    <Container>

    <h2>Welcome to new page....</h2>
    </Container>
    <Container2>
      
    </Container2>
    <div
        style={{
          width: "130px",
          height: "30px",
          padding: "20px",
          backgroundColor: "#2F2F57",
          position: "fixed",
          bottom: "15px",
          right: "17px",
          zIndex: 9999,
          borderRadius: "10px",
          cursor: "pointer",
          display: "flex",
        }}
        onClick={toggleModal}
      >
        <Icon.MessageCircle
          style={{
            position: "fixed",
            bottom: "20px",
            right: "115px",
            zIndex: 9999,
            height: "2em",
          }}
        />
        <img
          src={logo}
          alt=""
          style={{
            position: "fixed",
            bottom: "21px",
            right: "20px",
            zIndex: 9999,
            height: "2em",
          }}
        />
      </div>
      <Modal isOpen={isOpen} toggle={toggleModal} size="xl">
        <ModalBody style={{ backgroundColor: "#2F2F57" }}>
          <Chainlit />
        </ModalBody>
      </Modal>
  </>
  );
}



export default NewPage;
